.iconButton {
	&:global(.ant-btn-default) {
		&.disabled {
			&:hover {
				cursor: not-allowed;
				background: none;
			}
		}
		&:hover {
			background-color: #f1f3f5;
		}
		&.active {
			background-color: #e5e9ed;
		}
		&:focus {
			box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 4px #0a65e7;
		}
		border: none;
		box-shadow: none;
		height: 40px;
		width: 40px;
		padding: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: transparent;
	}
}
