@import 'theming/_variables.scss';

.label {
	@apply tw-leading-[18px] tw-text-[13px] tw-text-color-gray-900;
}

.slider {
	&:global(.ant-slider) {
		:global(.ant-slider-handle) {
			&::after {
				box-shadow: 0 0 0 2px $color-orange-500;
				@apply tw-h-4;
				@apply tw-w-4;
				top: -3px;
			}
		}
		:global(.ant-slider-track) {
			@apply tw-bg-color-orange-500;
			height: 2px;
		}
	}
}
