.il__navbar--container {
	height: calc(100vh);
	position: relative;
	display: flex;
	.il__navbar--strip {
		position: relative;
		z-index: 101;
		width: 64px;
		background: #f9fafb;
		border-right: 1px solid #d3d8df;
		height: 100%;
		padding: 16px 0px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;

		.il__navbar--menu-stack {
			padding: 0 4px;
		}
		.il__navbar--logo-container {
			display: flex;
			justify-content: center;
			position: relative;
			left: 0;
		}
		.il__navbar--products {
			display: flex;
			flex-direction: column;
			align-items: center;
			.il__navbar--product-single {
				.il__navbar--products_name {
					font-size: 10px;
					font-weight: 400;
					line-height: 12px;
				}
				&.il__navbar--product-active {
					.il__navbar--products_name {
						font-weight: 500;
					}
				}
			}
		}
		.il__navbar--user-stack {
			.il__navbar--user-single {
				padding: 2px 12px;
			}
		}
	}
	.il__navbar--list {
		width: 216px;
		z-index: 100;
		background-color: #fff;
		border-right: 1px solid #e5e9ed;
		border-left: 1px solid #e5e9ed;
		height: 100%;
		padding: 16px 8px;
		left: -216px;
		transition: left 0.4s;
	}
	.il__navbar--product-name {
		height: 52px;
		display: flex;
		align-items: center;
		h3 {
			margin: 0;
		}
	}
	.il__navbar--product-menu {
		margin-top: 17px;
	}
}
