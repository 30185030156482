.tag {
	@apply tw-rounded;
	@apply tw-py-1 tw-px-2;
	@apply tw-font-medium;
	@apply tw-text-font-size-20;
	@apply tw-leading-4;
	@apply tw-bg-white;
	&.small {
		@apply tw-py-1 tw-px-[2px];
		@apply tw-text-font-size-10;
		@apply tw-leading-3;
	}
	&.success {
		@apply tw-text-color-green-500;
		@apply tw-border-color-green-500;
	}
	&.blue {
		@apply tw-text-color-blue-500;
		@apply tw-bg-color-blue-50;
		@apply tw-border-color-blue-50;
	}
	&.warning {
		@apply tw-text-color-orange-600;
		@apply tw-bg-color-orange-50;
		@apply tw-border-color-orange-50;
	}
}
