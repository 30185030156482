.skeleton {
	&:global(.ant-skeleton) {
		margin-right: 5px;
		:global(.ant-skeleton-title) {
			border-radius: 6px;
			margin: 0;
			height: 100%;
		}
	}
}
