.carousel {
	@apply tw-h-full;
	:global(.carousel-root),
	:global(.carousel),
	:global(.slider-wrapper),
	:global(.slider),
	img {
		@apply tw-h-full;
		@apply tw-object-cover;
	}
	:global(.carousel-root) {
		:global(.carousel) {
			:global(.dot) {
				@apply tw-w-1 tw-h-1;
				@apply tw-rounded-lg;
				@apply tw-my-0 tw-mx-1;
				&:global(.selected) {
					@apply tw-w-2 tw-h-1;
					@apply tw-bg-color-gray-50;
					@apply tw-rounded-lg;
				}
			}
		}
	}
}
