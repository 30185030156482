@import '../../../theming/_init';

.datepicker {
	padding: 8px 12px;
	height: 40px;
	box-shadow: $shadow100;
	:global(.ant-picker-input) {
		input {
			color: $black900;
		}
	}
}
