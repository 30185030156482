@import '../../../theming/_init';

.select {
	&.normal {
		height: 40px;
		:global {
			div.ant-select-selector {
				height: 40px;
			}
		}
	}
	&.small {
		height: 32px;
		:global {
			div.ant-select-selector {
				height: 32px;
			}
		}
	}
	:global {
		div.ant-select-selector {
			border-radius: 6px;
			.ant-select-selection-placeholder {
				@apply tw-text-color-text-subtle tw-flex tw-items-center;
			}
			&:after {
				color: red;
			}
		}
	}
	:global {
		.ant-select-arrow {
			color: red;
		}
	}
}
.dropdown {
	width: auto !important;
}
