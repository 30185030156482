.il__main--wrapper {
	height: 100vh;
	display: flex;
	align-items: flex-start;
	overflow: hidden;
	position: relative;
	.il__navbar--wrapper {
		height: 100vh;
		@media only screen and (max-width: 1024px) {
			position: absolute;
			transition: left 0.3s;

			&.il__navbar--mobile_show {
				left: 0px;
			}
			&.il__navbar--mobile_hide {
				left: calc(-64px - 216px);
			}
		}
	}
	.il__body--wrapper {
		flex: 1;
		height: 100vh;
		overflow-y: auto;
		.il__body--mobile_ham {
		}
	}
}
