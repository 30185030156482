@import '../../../theming/_init';

.button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 12px;
	// height: 40px;
	box-shadow: $shadow100;
	border-radius: 6px;
	&:global(.ant-btn-default) {
		color: $primary;
		font-weight: $semibold;
	}
	&:global(.ant-btn-text) {
		@apply tw-text-color-text-primary tw-border-0 tw-p-0;
		box-shadow: none;
		&:hover {
			@apply tw-text-color-text-primary tw-bg-transparent;
		}
	}
}
