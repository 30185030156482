.tabs {
	@apply tw-max-w-full;
	&.fullWidth {
		:global(.ant-tabs-nav-list) {
			@apply tw-w-full;
		}
		:global(.ant-tabs-tab) {
			@apply tw-flex-1;
			@apply tw-justify-center;
		}
	}

	:global(.ant-tabs-nav) {
		&::before {
			display: none;
		}
	}

	:global(.ant-tabs-nav-list::before) {
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		border-bottom: 1px solid rgba(5, 5, 5, 0.06);
		content: '';
	}
	:global(.ant-tabs-tab) {
		@apply tw-text-color-black;
		padding-left: 8px;
		padding-right: 8px;

		&:global(.ant-tabs-tab-active .ant-tabs-tab-btn) {
			@apply tw-text-color-text-primary;
		}
	}
	:global(.ant-tabs-ink-bar) {
		@apply tw-bg-color-text-primary;
	}
}
