.modalMain {
	.ant-modal-content {
		@apply tw-p-0;
		.ant-modal-header {
			@apply tw-pl-6 tw-pr-6 tw-pt-6 tw-mb-0;
			.ant-modal-title {
				@apply tw-font-medium tw-text-font-size-50 tw-leading-7 tw-text-color-surface-dark;
			}
		}
		.ant-modal-close {
			@apply tw-flex tw-items-end tw-justify-center;
			.ant-modal-close-x {
				@apply tw-flex tw-items-center tw-justify-center;
			}
		}
		.ant-modal-footer {
			@apply tw-p-6;
			@apply tw-flex tw-justify-end;
			.okBtn,
			.cancelBtn {
				min-width: 92px;
			}
		}
		.modalBody {
			@apply tw-p-6;
		}
		.ant-modal-close {
			@apply tw-top-6 tw-right-6 tw-pb-1;
		}
	}
}
